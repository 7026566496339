.question-component {
  color: #000;
  font-size: 20px;

  .tooltip-heading {
    color:#9a9a9a
  }

  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }

  .question-title {
    font-weight: bold;
    text-align: left;
  }
  
  p {
    margin-bottom: 0;
    color: inherit;
    
    &.question-text {
      line-height: 23px;
      margin-top: 30px;
      text-align: left;
      max-width: 90%;

      img {
        max-width:100%;
        width: auto!important;
        height: auto!important;
      }

      @include media-breakpoint-down(sm) {
        line-height: 18px;
      }
    }
  }

  .question-options {
    text-align: left;
    margin-top: 20px;

    ol {
      padding-left: 18px;
      
      li {
        list-style: lower-alpha;
        
        &.correct {
          color: $green-ocean;
        }
      } 
    }

    .liNumber {
      li {
        list-style: decimal!important;
      }
    }
  }
}

.question-options--correct{
  color: $green-ocean;
  &::before{
    background-color: $green-ocean;
  }
}

.question-badge {
  background-color: #f2f2f2;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 10px 7px 0;
  position: relative;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  line-height: 1rem;
  cursor: pointer;
  font-size: 13px;
  height: 40px;
  min-width: 40px;
  padding: 11px 12px;


  a {
      transition: transform 100ms ease;
      background-color: $orange;
      border-radius: 10px;
      transform: scale(1);
      position: absolute;
      color: #FFFFFF;
      font-size: 18px;
      right: -6px;
      z-index: 1;
      top: -8px;
  }
}

.used-times-badge {
  background-color: #F78E00;
  display: inline-block;
  margin: 0 10px 7px 0;
  position: relative;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  line-height: 1rem;
  cursor: default;
  font-size: 12px;
  height: 40px;
  min-width: 40px;
  padding: 11px 12px;
  color: #FFFFFF;
}
