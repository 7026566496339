.card {
  .fixed-actions {
    display: flex;
    height: 100%;
    flex-direction: column;

    a {
      margin-bottom: 5px;
    }
  }

  .questionsAnswersCount {
    // display: 'flex';
    // flex-direction: 'row';
    // justify-content: 'space-between';
    // min-width: '270px';
    // padding-bottom: '5px';

    div {
      // flex-grow: 1;
      // width: 33%;
      // height: 100px;
      text-align: center;
      flex: 33.33%;
    }
  }

}