div.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 71px;
  margin: auto;
  // background: #4d4d4d;
  // color: #ffffff;
  z-index: 1020;

  display: block;
  @media (max-width: 991.98px) {

    &.slideFooter {
      overflow-y: hidden;
      bottom: 60px;
    
      transition-property: all;
      transition-duration: .5s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    &.closed {
      bottom: -60px!important;
    }
  }
  
  div.footer-content {

    background: #4d4d4d;
    color: #ffffff;

    div.container {
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      padding: 14px 7px;

      div.logo-sm a img{
        height: 30px;
      }

      div.links {
        text-align: center;
        font-size: 14px;

        h5 {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0.3rem;
        }

        a {
          cursor: pointer;
          color: #ffffff; 
        }

        a:hover {
          text-decoration: underline;
          color: #ffffff; 
        }

        span {
          margin: 0 3px;
        }

      }

      div.social {
        display: flex;
        align-items: center;

        a {
          margin: 0 8px;
          font-size: 18px;
          cursor: pointer;
          color: #ffffff; 
        }

        a:hover {
          color: #ffffff; 
        }

        a:last-child {
          margin-right: 0;
        }

      }

    }

  }

}