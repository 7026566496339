.btn{
    white-space: normal;
}

a.btn,
input.btn,
button.btn {
    text-transform: uppercase;
    font-family: $font-primary;
    padding: 0.85rem 0.75rem;
    font-weight: bold;
    min-width: 100%;
    font-size: 15px;

    @include media-breakpoint-down(sm) {
        padding: 0.5rem 0.75rem;
        min-width: auto;
    }
    
    &:not([class*="btn-outline-"]) {
        border: none;
    }
    
    // default states
    &.btn-warning {
        background-color: $orange-light;
        color: #fff;
    }

    &.btn-light {
        background-color: #E1E0E0;
    }

    &.btn-light-gray {
        background-color: #f8f8f8;
        color:#9a9a9a;
        border: 1px solid #d3cdcd;
        margin:5px 0;
        font-weight: 500;
        min-height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none;
        font-size: 18px;
    }

    &.btn-light-gray-block {
        background-color: #f8f8f8;
        color:#9a9a9a;
        border: 1px solid #d3cdcd;
        margin:5px 0;
        font-weight: 500;
        min-height: 74px;
        display: block;
        text-transform: none;
        font-size: 18px;

        .btn-name-block{
            display: block;
        }
    }

    &.central{
        padding-top: 25px;
    }

    // hover and active states
    &:hover,
    &.active,
    &:active {
        &.btn-warning {
            background-color: $orange !important;
            color: #fff !important;
        }
        &.btn-light-gray {
            background-color: $orange-light !important;
            color: #fff !important;
            border: 1px solid $orange-light;
            margin:5px 0;
            font-weight: bold;
        }
        &.btn-light-gray-block {
            background-color: $orange-light !important;
            color: #fff !important;
            border: 1px solid $orange-light;
            margin:5px 0;
            font-weight: bold;
        }
    }

    // disabled
    &.disabled {
        background-color: $gray-light;
        pointer-events:none;
    }
    
    &.btn-sm,
    &.btn-group-sm > .btn {
        border-radius: 7px;
        padding: 0 1.5rem;
        min-width: auto;
    }
    
    &.btn-outline-warning {
        border-color: $orange-light;
        color: $orange-light;
    
        &:hover,
        &:active,
        &.active {
            background-color: $orange !important;
            color: #fff !important;
            border-color: $orange;
        }
    }
}

// Adicionar Mais
a.add-more {
    text-decoration: none !important;
    color: $orange;
    margin: auto;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
        background-color: $white-ice;
        text-align: center;
        box-shadow: none;
        border: 1px solid $gray-light;
        display: block;
        flex-flow: row;
        align-items: center;
        text-decoration: none;
        padding: 30px 60px;
    }
    
    &:hover,
    &:active {
        color: $orange;
    }
    
    i.ti-plus {
        background-color: $orange;
        border-radius: 30px;
        margin-right: 10px;
        color: #ffffff;
        padding: 10px;

        @include media-breakpoint-down(sm) {
            display: block;
            margin: auto;
            margin-bottom: 10px;
            max-width: 36px;
            background-color: transparent;
            border: 1px solid $orange;
            color: $orange;
            font-size: 25px;
            padding: 5px;
        }
    }

    i.ti-minus {
        background-color: $orange;
        border-radius: 30px;
        margin-right: 10px;
        color: #ffffff;
        padding: 10px;

        @include media-breakpoint-down(sm) {
            display: block;
            margin: auto;
            margin-bottom: 10px;
            max-width: 36px;
            background-color: transparent;
            border: 1px solid $orange;
            color: $orange;
            font-size: 25px;
            padding: 5px;
        }
    }
}

// Mobile
.btn-mob {
    @include media-breakpoint-down(sm) {
        margin: 20px 0 50px 0;

        a {
            width: 100%;
        }
    }
}

.btn-lg-mob {
    display: none;

    @include media-breakpoint-down(sm) {
        display: inline-block;
        margin-top: 20px;
    }
}

.btn-width-mob {
    @include media-breakpoint-down(sm) {
        width: 54%;
    }
    @include media-breakpoint-down(xs) {
        width: 40%;
    }
}

.btn-modal-mob {
    @include media-breakpoint-down(sm) {
        font-size: 15px;

        span {
            display: none;
        }
    }
}

.btn-sort {
    @include media-breakpoint-down(sm) {
        padding: 10px 20px !important;
    }
}

