.assessment-answer--background-title{
    padding: 20px;
    background-color: #F78E00;
    border-radius: 0;
    text-align: left;

    h3, h5{
        color:#fff;
        margin:0
    }

    .padding-0{
        padding:0;
    }
}

.assessment-answer--background-text{
    padding: 20px;
    text-align: left;

    h5{
        color:#9a9a9a;
        margin:0;
        display: inline;
    }

    p{
        color:#424242;
        margin:0;
        display: inline;
    }

    &:nth-child(odd){
        background-color: #e2e2e2;
    }

    &:nth-child(even){
        background-color: #f8f8f8;
    }

}

.grid-feedback{
    padding-right: 0 !important;
    padding-left: 0 !important;

    .grid-feedback-column{
        height: 100%;
    }
}

.card-more-assessment {
    border-top: 1px solid #E1E0E0;
    text-decoration: none;
    display: block;
    color: #8D8D8D;
    font-size: 16px;
    margin: 20px 0px;
    padding: 10px;
    cursor: pointer;
    
    &:hover {
        color:#F78E00!important;
    }
}

.bar-chart.horizontal-bars-chart .bar-chart-wrapper .padding{
    padding: 10px 30px 20px 0!important;
}

.bar-chart.horizontal-bars-chart .bc-row {
    flex-direction: column!important;
}

.bar-chart.horizontal-bars-chart .bc-row p{
    padding: 0!important;
    margin:0!important;
}

.assessment-answer--row {
    margin: 0 !important;
    width: 100%;
    & img{
        max-width: 100%;
    }
}

.assessment-answer--container{
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
}

.feedback-color, .feedback-color p{
    color: #FF00FF!important;
    margin: -40px 0 -40px 8px !important;
}

.positionBefore {
    position: absolute;
    top: 43.9%;
    left: -40px;
}

.divFlex {
    display: flex;
}

.borderGreen {
    border: 1px solid #108E31;
    border-radius: 15px;
    padding: 0 0 10px 0;
}

.blank-answer {
    cursor: pointer;
    padding: 5px;
    margin-right: 2px;
}

.bgGray {
    background-color: #d9d9d9;
    border: 1px solid #cdcdcd;
}

.bgGreen {
    background-color: #82ff93;
    border: 1px solid #82ff93;
}

.bgRed {
    background-color: #ffa2a2;
    border: 1px solid #ffa2a2;
}

.allQuestions {
	.open-question {
		border: 2px solid black;
		min-height: 50px;
		width: calc(100% - 50px);
		margin-left: 50px;
		margin-top: 20px;
		padding: 10px;
		color: black;
		border-radius: 5px;
		white-space: break-spaces;
	}

	.question-unitary, .question-unitary-context {
		position: relative;
		// display: flex;
		// flex-direction: row;

		.question-block {
			display: flex;
			flex-direction: row;
			width: 100%;

			.question-number {

				.badge-dark {
					height: 40px;
					min-width: 40px;
					text-align: center;
					line-height: 40px;
					padding: 0;
				}
			}

			.question-item {
				display: flex;
				flex-direction: column;
				width: 100%;

		img {
			max-width: 100%;
        width: auto!important;
        height: auto!important;
		}
	
				.fixed-actions {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					// line-height: 1rem;
					// right: 0;
					// top: 10px;
					// z-index: 1;
					button {
						background: none;
						border: none;
						text-decoration: none;
						display: inline-block;
						font-size: 0.7rem;
						color: #a2a2a2;
						cursor: pointer;
					}
					i { 
						font-size: 1.2rem;
						display: block;
					}
					.grade {
						height: 40px;
						min-width: 40px;
						text-align: center;
						line-height: 40px;
						padding: 0;

						// padding: 7px 13px 7px 13px;
						border-radius: 5px;
						border: 1px solid gainsboro;
						font-size: 13px;
						box-sizing: content-box;
						margin-right: 10px;
						font-weight: 900;

						&.notQualified {
							font-size: 16px;
							color: #ffa500;
							border-color: #f9d99f;
						}

					}
				}

				.enunc {
					display: block;
					top: 0;
					left: 0;
					width: 100%;
					margin-top: 8px;
					padding-left: 15px;
				}

				.question-answer {
					padding-left: 15px;

					.open-question {
						width: 100%;
						margin-left: 0px;
					}

					p {
						margin: 20px 0;
					}

				}
			}

		}

		// .question-context{
		// 	padding-bottom: 0;
		// }

	}

	.question-unitary, .question-unitary-context {
		.question-header {
			display: flex;
      justify-content: space-between;
      align-items: flex-start;

			.enunc {
				left: 10px;
				top: 0;
				width: 100%;

				p {
					margin: 0 !important;
					padding: 0;
				}
			}

			.question-feedback {
				color: #000;
				font-size: 32px;
				transition: color 0.2s;

				&:hover {
					color: #F78E00;
				}
			}
		}
	}

	// .question-unitary {
	// 	position: relative;
	// }
	// .fixed-actions {
	// 	position: absolute;
	// 	text-align: center;
	// 	line-height: 1rem;
	// 	right: 0;
	// 	top: 10px;
	// 	z-index: 1;
	// 	button {
	// 		background: none;
	// 		border: none;
	// 		text-decoration: none;
	// 		display: inline-block;
	// 		font-size: 0.7rem;
	// 		color: #a2a2a2;
	// 		cursor: pointer;
	// 	}
	// 	i { 
	// 		font-size: 1.2rem;
	// 		display: block;
	// 	}
	// 	.grade {
	// 		width: 100px;
	// 		padding: 7px 13px 7px 13px;
	// 		border-radius: 5px;
	// 		border: 1px solid gainsboro;
	// 		font-size: 13px;
	// 		box-sizing: content-box;
	// 		margin-right: 10px;
	// 		font-weight: 900;
	// 	}
	// }
}

.grade-h5p {
    width: auto;
    padding: 7px 13px 7px 13px;
    border-radius: 5px;
    border: 1px solid gainsboro;
    font-size: 13px;
    box-sizing: content-box;
    margin-right: 10px;
    font-weight: 900;
    position: absolute;
    right: 0;
    top: 20px;
}