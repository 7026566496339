.result-student-dialog {
	.actions-container {
		height: 14px;
		position: relative;
		.fixed-actions {
			position: absolute;
			text-align: center;
			line-height: 1rem;
			padding: 10px;
			right: 0;
			top: 0;
			z-index: 1;
			button {
				background: none;
				border: none;
				text-decoration: none;
				display: inline-block;
				font-size: 0.7rem;
				color: #a2a2a2;
				cursor: pointer;
			}
			i {
				font-size: 1.2rem;
				display: block;
			}
		}
	}

	.question-unitary-context {
		.enunc-alt {
			font-family: 'Arial';
			font-size: 12pt;
		}

		img {
			max-width: 100%;
        width: auto!important;
        height: auto!important;
		}
	}
}