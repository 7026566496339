.bar-chart {

	&.vertical-bars-chart {

    .bar-chart-wrapper {
      justify-content: flex-start !important;
    }

    .bc-column {

      .bc-bar {

        .bc-bar-value {

          width: 180px !important;
          margin-left: -90px !important;
          padding: 5px !important;

          .info {
            font-size: 12px;
            display: block;
            text-align: left;

            .label {
              display: inline-block;
              margin-right: 5px;
            }
            .value {
              color: #212529;
            }
          }

        }

      }

    }

  }

}
