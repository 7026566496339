.header {
    text-align: left;
    color: #000;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }
    
    .badge-light {
        border: 1px solid $gray-light;
        background-color: $white-ice;
        display: inline-block;
        height: 100px;
        width: 30%;
        text-align: center;
        display: inline-flex;
        align-items: center;

        img.upload-logo-button {
            height: initial;
            width: initial;
            max-height: 100%;
            margin-top: 0;
        }
    }

    .badge-mob {
        @include media-breakpoint-down(sm) {
            position: relative;
            width: 100%;
            height: 80px;
            top: 0;
            margin-bottom: 10px;
        }
    }
    
    .pa-info {
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;
        width:460px;

        @include media-breakpoint-down(lg) {
            width:230px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            text-align: center;
            display: block;
            margin-left: 0;
        }

        h2 {
            @include media-breakpoint-down(sm) {
                font-size: 1.2rem;
                margin: 0;
            }
        }
        
        p {
            font-weight: bold;
            font-size: 17px;
            color: #000;
            margin: 0;

            @include media-breakpoint-down(sm) {
                font-size: 15px;
                font-weight: normal;
            }
        }
    }
    
    .pa-version {
        display: block;
        text-align: center;
        float: right;

        @include media-breakpoint-down(sm) {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            float: none;
        }
        
        .version {
            margin: 0 0 -20px 0;
            font-weight: bold;
            font-size: 17px;
            color: #000;
            
            @include media-breakpoint-down(sm) {
                margin: 0 0 -10px 0;
            }
        }
        
        
        .letter {
            font-weight: bold;
            font-size: 60px;
            margin: 0 -5px;
            
            @include media-breakpoint-down(sm) {
                font-weight: normal;
                font-size: 40px;
            }
        }
        
        i {
            font-weight: bold;
            font-size: 40px;
            cursor: pointer;
            
            @include media-breakpoint-down(sm) {
                font-size: 30px;
                color: $orange;
                font-weight: bold;
            }
        }
    }
}

.previa-avaliacao {
    .pa-version {
        @include media-breakpoint-down(sm) {
            position: absolute;
            top: -40px;
            left: 40px;
            right: 40px;
        }
    }

    .badge-mob {
        @include media-breakpoint-down(sm) {
            position: relative;
            width: 100%;
            height: 80px;
            top: 50px;
        }
    }
}

.header-form {
    @include media-breakpoint-down(sm) {
    }

    form {
        text-align: left;
        margin-bottom: 0;
        width: 100%;
        
        .form-group {
            margin-top: 25px;
            
            label {
                font-weight: bold;
                color: #000;
            }
            
            .form-control {
                background-color: white;
                border: 1px solid #000;
                padding: 20px 10px;
                margin-top: 5px;
                color: #000;
                font-size: 20px;
            }
        }
    }
}

.header-warning {
    text-align: left;
    
    p {
        //border-bottom: 2px solid $gray-dark;
        padding-bottom: 20px;
        font-weight: bold;
        font-size: 16px;
        color: #000;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            margin-top: 20px;
        }
    }
}

.question-unitary {
    border-bottom: 2px solid #000000;
    text-align: left;
    padding: 20px 0;

    .badge-dark {
        display: inline-block;
        border-radius: 5px;
        padding: 6px 14px;
        font-size: 18px;

        @include media-breakpoint-down(sm) {
            text-align: center;
            display: block;
            width: 57px;
        }
    }
    
    h3 {
        position: relative;
        font-size: 16px;
        color: #000;
        left: 60px;
        top: -40px;
        width: calc(94% - 70px);
        margin: 0;
        
        @include media-breakpoint-down(sm) {
            left: 0;
            top: 10px;
            font-size: 15px;
        }
    }
    
    p {
        margin: -20px 0 0 50px;
        font-size: 16px;
        color: #000;

        @include media-breakpoint-down(sm) {
            margin: 20px 0 0 0;
        }
        
        // span {
        //     //font-weight: bold;
        // }
    }
    
    ol {
        list-style: none;
        margin-left: 25px;
        color: #000;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            padding-left: 0;
        }
    }

    img {
        max-width: 100%;
        width: auto!important;
        height: auto!important;
    }
}

.question-unitary:last-child {
    padding-bottom: 0;
    border: none;
}

.question-unitary-context {
    // border-bottom: 2px solid #000000;
    text-align: left;
    padding: 20px 0;

    .messageRight {
        margin: 30px 40px 0;
    }

    .badge-dark {
        display: inline-block;
        border-radius: 5px;
        padding: 6px 14px;
        font-size: 18px;

        @include media-breakpoint-down(sm) {
            text-align: center;
            display: block;
            width: 57px;
        }
    }
    
    h3 {
        position: relative;
        font-size: 16px;
        color: #000;
        left: 50px;
        top: -40px;
        width: 94%;
        margin: 0;

        @include media-breakpoint-down(sm) {
            left: 0;
            top: 10px;
            font-size: 15px;
        }
    }
    
    p {
        margin: -20px 0 0 50px;
        font-size: 16px;
        color: #000;

        @include media-breakpoint-down(sm) {
            margin: 20px 0 0 0;
        }
        
        span {
            font-weight: normal;
        }
    }
    
    ol {
        list-style: none;
        margin-left: 25px;
        color: #000;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            padding-left: 0;
        }
    }
}

.question-unitary-context:last-child {
    padding-bottom: 20px;
    border-bottom: 2px solid #000000;
}

.question-context{
    padding: 20px 0;
    text-align: left;
}

.allQuestions {
    div:last-child {
        .question-unitary-context:last-child {
            padding-bottom: 20px;
            border-bottom: none;
        }
    }

    img {
        max-width: 100%;
        width: auto!important;
        height: auto!important;
    }
}