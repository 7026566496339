.content-wrapper--extended{
    padding-left: 150px;
    @media (max-width: 991.98px) {
     padding: 150px 15px 30px 15px;
    }

    @media (max-width: 575px) {
        padding: 150px 15px 100px 15px;
    }
}

.container--extended{
    @media (min-width: 1200px){
        max-width: 1588px;
    }
}