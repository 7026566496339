// Sort Bar
.sort-bar {
    padding: 7px 0;
    height: auto;
    width: 100%;
    
    .select2 {
        width: 150px !important;
        margin-top: -13px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
        
        .select2-selection__rendered {
            text-align: left !important;
        }
        
        .select2-selection {
            padding: 0.2rem;
            
            .select2-selection__arrow {
                b {
                    border-color: orange transparent transparent transparent;
                    border-width: 7px 6px 0 6px;
                    margin-left: -10px;
                    margin-top: -3px;
                }
            }
        }
    }
    
    .fb-icons {
        display: inline-block;
    
        @include media-breakpoint-down(sm) {
            display: none;
        }
        
        a {
            border: 1px solid transparent;
            padding: 2px 1px 1px 2px;
            display: inline-block;
            color: $gray-light;
            text-align: center;
            border-radius: 3px;
            margin-left: 13px;
            font-size: 22px;
            height: 35px;
            width: 35px;

            &.active,
            &:hover {
                border-color: $orange;
                text-decoration: none;
            }
        }
    }

    .btn {
        margin-top: -9px;
        float: right;

        .mob {
            display: none;

            @include media-breakpoint-down(sm) {
                display: inline-block;
                margin-right: 10px;
            }
        }

        span {
            display: inline-block;
            margin-left: 2px;
            
            @include media-breakpoint-down(sm) {
                display: none;
            }

            .btnTextAssessment {
                display: inline-block;
            }
        }

        .btnTextAdd{
            display: inline-block;

            span {
                @include media-breakpoint-down(sm) {
                    display: inline-block;
                }
            }
        }
    }
}

.selectNAQ{

    
    
    .select2 {
        background-color: #f8f8f8;
        color: #9a9a9a;
        margin: 5px 0;
        font-weight: 500;
        display: block;
        text-transform: none;
        font-size: 18px;
        border-radius: 0.25rem;

        .select2-selection__rendered {
            text-align: left !important;
        }
        
        .select2-selection {
            padding: 0.2rem;
            min-height: 74px;
            display: flex;
            align-items: center;
            
            .select2-selection__arrow {
                b {
                    border-color: orange transparent transparent transparent;
                    border-width: 7px 6px 0 6px;
                    margin-left: -10px;
                    margin-top: -3px;
                }
            }
        }
    }
}

.Select-menu-outer{
    z-index: 5000!important;
}
