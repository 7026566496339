.report-details{
    .card-title {
        display: block;
        margin-bottom: 15px !important;
    }
    .actions {
        display:flex;
        justify-content: center;
        div{
            max-width: 200px;
            margin-bottom: 20px;
        }
    }
}
.report-details-header{
    text-align: center;
}

.answer-question--container {
    img {
        max-width: 100%;
    }
}