.steppers {
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    z-index: 1;

    .stepper {
        .stepper-number {
            @include transition;
            cursor: pointer;
            border: 2px solid $gray-light;
            background-color: #fff;
            border-radius: 20px;
            color: $gray-light;
            padding-top: 1px;
            height: 30px;
            width: 30px;
            margin: auto auto 15px auto;

            i.ti-check {
                display: none;
            }

            &.active {
                background-color: $orange-light;
                border: 2px solid $orange-light;
                color: #ffffff;
                span {
                    color: $orange-light;
                }
                i.ti-check {
                    display: none;
                }
                &:after{
                    border-bottom: 2px solid $orange-dark !important;
                }
            }
            
            &.checked{
                background-color: $orange-dark;
                border: 2px solid $orange-dark;
                color: #ffffff;
                font-size: 0;
    
                i.ti-check {
                    font-weight: bold;
                    font-size: 14px;
                    margin-top: 5px;
                    display: block;
                }
            }
            
        }

        span {
            @include transition;
            color: $gray-light;
            font-size: 12px;
        }
    }

}
