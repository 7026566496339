.form-group {

  .f-s-12 { font-size: 12px; }

  .form-error {
    display: inline-block;
    margin-top: 5px;
    font-size: 12px;
    color: red;
  }

  label {
    &.inline-label {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &.display-flex {
    display: flex;
    justify-content: space-between;

    .form-item {
      width: 100%;

      & + .form-item {
        margin-left: 30px;
      }

    }

    

  }

} 

a.modal-button {
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.modal-button.disabled:hover {
    color: #999;
  }
}
