$modal-offset-top: 65px;

.modal.fade .modal-dialog {
    transform: scale(1);
}

.modal.show {
    display: block;
    overflow: auto;
    .modal-dialog {
        transform: scale(1);
    }
}

.modal-dialog {
    max-width: 650px;
    margin: 6.75rem auto;

    &.modal-sm {
        max-width: 450px;
    }
}

.modal-content {
    box-shadow: $shadow-default;
    border: none;
    margin: 0 20px;

    @include media-breakpoint-down(xs) {
        margin: 0;
    }
}

.modal-header {
    border: none;
}

.modal-body {
    padding: 0 2rem 2rem;
    text-align: center;

    i.icon {
        font-size: 70px;
        color: orange;
    }

    .modal-title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 25px;
        color: orange;
    }

    .modal-title-question{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 25px;
        color: orange;
        margin-bottom: 40px;
    }

    p {
        color: #BFBFBF;
        margin: 20px 0;
    }

    li{
        list-style-type: lower-alpha;
    }
}

.modal-title-question-added{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
    color: orange;
    margin-bottom: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;

    i {
        font-size: 70px;
    }
}

.modal-footer {
    padding: 0;
}

.modal-footer-question {
    padding: 15px;
    border-top: 1px solid #ddd
}
.modal-footer-question-added {
    border-top: 1px solid #ddd;
    padding: 2rem;

    .text{
        font-size: 16px;
        margin: 20px 0;

        &:last-child{
            margin: 0;
        }
    }
   
}

.margin0, .margin0 span{ margin: 0!important; display: block; }

a.modal-button {
    border-right: 1px solid #e9ecef;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    text-align: center;
    line-height: 1.2em;
    padding: 0 25px;
    font-size: 18px;
    color: #999;
    z-index: 9999;
    display: flex;
    height: 80px;
    flex: 1;
    cursor: pointer;
    // text-transform: capitalize;

    @include media-breakpoint-down(sm) {
        padding: 0 15px;
    }
    
    &.modal-button:hover {
        color: $orange;
    }
    
    &:last-child {
        border-right: none;
    }
}

.modal-backdrop {
    background: #6a5d5d;
    display: none;
    &.show { display: block; }
}

.footer-simple {
    color: $orange;
    padding: 2rem;
    border: none;
    
}

.information {
    padding: 15px 20px;
    cursor: pointer;
    
    p {
        display: inline-block;
        margin-bottom: 0;
    }
    
    i {
        margin-right: 10px;
        font-size: 20px;

        .info-floating {
            background-color: $white-ice;
            box-shadow: $shadow-hover;
            position: absolute;
            max-width: 200px;
            padding: 15px;
            bottom: -16%;
            right: 24%;
            
            .if-title {
                font-weight: bold;
                font-size: 18px;
                
                i {
                    margin: 0 -3px 15px 0;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
            
            .if-subtitle {
                color: $gray-dark;
                font-size: 15px;
                
                i {
                    font-size: 11px;
                    margin: 0;
                }
            }
            
            .info-text {
                color: $gray-dark;
                font-size: 13px;
                margin-top: 3px;
            }
            
            .if-font {
                color: $gray-dark;
                margin-top: 10px;
                
                i {
                    font-size: 14px;
                    margin: 0;
                }
                
                p {
                    font-size: 14px;
                    
                    span {
                        color: black;
                    }
                }
            }

            &:before {
                background-color: $white-ice;
                box-shadow: $shadow-hover;
                transform: rotate(45deg);
                position: absolute;
                content: " ";
                height: 30px;
                width: 30px;
                right: -3%;
                top: 28%;
            }
        }
    }

    &:hover {
        i {
            .info-floating {
                display: inline-block;
            }
        }
    }
}

.footer-third {
    display: inline;
    padding: 20px;
    border: none;
}

.footer-fourth {
    a {
        padding: 5px 0;
    }
}

// Nível anterior
.modal-body {
    .nivel-anterior-icon {
        border: 2px solid orange;
        border-radius: 50px;
        font-size: 30px;
        padding: 10px;
    }

    .nivel-anterior {
        margin-top: 15px;
    }
}

// Resultado por Aluno
.resultado-aluno {
    margin-top: 15px;
    font-weight: bold;
    font-size: 23px;
}

.modal-body {
    p {
        color: $gray-dark;
        margin-top: 10px;
    }

    .bar-chart {
        padding-top: 0;
    }

    .conteudo {
        .conteudo-title {
            font-weight: bold;
            margin-top: 10px;
            font-size: 18px;
            color: orange;

            i {
                margin-right: 10px;
                font-size: 17px;
            }
        }
    }
}

// Prévia Avaliação
.modal-dialog.modal-dialog-centered {
    // max-width: 900px;
   // margin-top:120px;
}

.modal-dialog.modal-dialog-md {
    max-width: 900px;
}
.modal-dialog.modal-dialog-lg {
    max-width: 1200px;
}

// .modal{
//     overflow: auto!important;
// }

.modal{
    background-color: rgba(0,0,0,0.3);
    // overflow: auto !important;
    // display: block !important;
    // // pointer-events: none;
    &.fade {
      transition: opacity ease 300ms;
    }
    &.show{ 
      // pointer-events: all;
      display: block;
      padding-right: 12px;
      opacity: 1;
    }
    &.hidden{
      opacity: 0;
    }
  }

.modal-question{
    position: fixed;
    top: $modal-offset-top;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow-y: auto;
    overflow-x: auto;
    outline: 0;
}

.modal-question-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #6a5d5d;
    opacity: 0.5;
}

.footer-simple {
    padding: 0 15px 15px;
    color: $gray-dark;
    font-size: 15px;
}

.icon-footer {
    font-weight: bold;
    font-size: 21px;
    color: $orange;
}

.disable {
    color: $gray-dark;
}

.summary {
    padding-bottom: 10px;
    clear: both;
}

.summary-unitary {
    white-space: nowrap;
    text-align: center;
    font-size: 14px;

    
    .percent {
        margin-top: -5px !important;
        font-weight: bold;
        font-size: 30px;

        span {
            font-size: 15px;
        }
    }

    &.featured {
        .percent {
            color: $orange;
        }
    }
}

.footer-mob {
    justify-content: center;
}

.flex {
    display: flex;
}

.wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.space-evenly {
    justify-content: space-evenly;
}
