.details-question-table{
    width: 100%;
    table {
        width: 100%;
    }
    .correct {
        background-color: #dcedc8
    }
    .answer-catalog-container {
        display: flex;
        justify-content: center;
    }
    .answer-catalog {
        font-weight: bold;
        max-width: 300px;
        background-color: #ffd54f;
        padding: 15px;
    }
}
.details-question-table--header {
    padding: 10px;
}