.optin {
  .close {
    display: none;
  }
  .btn.disabled {
    background-color: #ffb900!important;
  }
  .optin-form {
    margin: 50px 0 20px 0;
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  input.switch + label {
    width: fit-content;
    margin: auto;
  }
  .btn-secondary {
    border: 1px solid #ffb900;
    background: #fff;
    width: 100%;
    color: #ffb900;
    padding: 14px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn-secondary:hover {
    background: #ffb900;
    color: #fff;
  }
}

