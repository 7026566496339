input.switch {
  display: none;

  + label {
    position: relative;
    padding-left: 50px;
    user-select: none;
    cursor: pointer;

    &:before {
      content: '';
      transition: background-color 200ms ease;
      background-color: #D3D3D3;
      border-radius: 10px;
      position: absolute;
      height: 20px;
      width: 45px;
      left: 0;
      top: 0;
    }

    &:after {
      content: '';
      transition: left 200ms ease;
      background-color: #fff;
      border-radius: 10px;
      position: absolute;
      height: 16px;
      width: 16px;
      left: 2px;
      top: 2px;
    }
  }

  &:checked + label {
    color: $green-ocean;

    &:before {
      background-color: $green-ocean;
    }

    &:after {
      left: 27px;
    }
  }
}
