.content-wrapper{
    &.student {
      padding-left: 230px!important;
    }

    @media (max-width: 991.98px) {
      &.student {
        padding-left: 15px!important;
      }
    }
}