.card-materia,
.card-materia-list {
    text-align: center;

    .cm-title {
        font-family: $font-primary;
        text-transform: uppercase;
        white-space: nowrap;
        font-weight: bold;
        color: #867A65;
        font-size: 1vw;
        margin: 0;

        @include media-breakpoint-down(lg) {
            font-size: 1.3vw;
        }

        @include media-breakpoint-down(md) {
            font-size: 25px!important;
        }
    }
     
    .cm-questions {
        white-space: nowrap;
        font-size: 13px;
        color: gray;
        margin: 0;

        @include media-breakpoint-down(lg) {
            font-size: 11px;
        }
    }
    
    .cm-info {
        a {
            border-right: 1px solid gray;
            white-space: inherit; //nowrap;
            padding-right: 10px;
            margin-right: 10px;
            font-size: 12px;
            color: gray;

            @include media-breakpoint-down(lg) {
                font-size: 11px;
            }
        }
        
        a:last-child {
            border-right: none;
            padding-right: 0;
            margin-right: 0;
        }
        
        a:hover {
            text-decoration: none;
            color: orange;
        }
    }
    
    .cm-date {
        color: grey;
        // color: lightgray;
        margin-top: 10px;
        font-size: 12px;

        @include media-breakpoint-down(lg) {
            font-size: 11px;
        }
    }
}

// Card Matéria list
.card-materia-list {
    text-align: left;

    @include media-breakpoint-down(sm) {
        .cm-title {
            width: 80px;
        }

        .badge {
            font-size: 50%;
        }
    }

    
    
    .cm-title,
    .cm-questions {
        left: 70px;
        top: 35px;
    }

    .cm-questions {
        top: 50px;
    }
    
    .cm-info {
        line-height: 17px;
        right: 140px;
        top: 25px;
        
        
        ul {
            padding: 0;
            list-style: none;
    
            li a {
                font-size: 13px;

                @include media-breakpoint-down(lg) {
                    font-size: 11px;
                }
            }
        }
    }
    
    .cm-date {
        right: 49px;
        width: 80px;
    }
}

.card-body-mob {
    @include media-breakpoint-down(lg) {
        padding: 1rem;
        
        .card-menu {
            top: 0;
            right: -5px;
        }

        .cm-icon {
            font-size: 35px;
            margin-top: 5px;
        }
    }
}
