.form-control {
    background-color: $white-ice;
    border: 1px solid #e1e0e0;
    border-radius: 0;
    font-weight: bold;
    color: $gray-dark;
    width: 100%;
    font-size: 0.9rem;
    height:50px;

    @include media-breakpoint-down(sm) {
        padding: 0.5rem 0.75rem;
    }
    
    &:focus {
        background-color: $white-ice;
        border-color: $orange;
        box-shadow: none;
    }
}

.form-control-rich {
    background-color: $white-ice;
    border: none;
    padding: 0;
    border-radius: 0;
    font-weight: bold;
    color: $gray-dark;
    width: 100%;

    @include media-breakpoint-down(sm) {
        padding: 0.5rem 0.75rem;
    }
    
    &:focus {
        background-color: $white-ice;
        border-color: $orange;
        box-shadow: none;
    }
}

.ql-toolbar.ql-snow{
    background-color: $white; 
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: 150px;
}

.form-control-mob {
    @include media-breakpoint-down(lg) { 
        font-size: 0.8rem;
      }
}

.form-group {
    margin: 30px 0 0 0;
    
    @include media-breakpoint-down(sm) {
        display: block;
        max-width: 100%;
    }

    label {
        font-family: $font-primary;
        color: $gray-dark;
        margin-bottom: 0;
        font-size: 14px;
        display: block;

        &.inline{
            display: inline;
            margin-left: 5px;
        }
    }

    &.selected {
        color: $green-ocean;
    }

    .select2 {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }

    &.fg-right{
        text-align: right;
    }

    .select2-container{
        width:100%!important;
    }

    &.has-error, .has-error {
        color: $red;
        label.has-error, span.has-error {
            color: $red;
        }
        textarea {
            color: $red;
            border-color: $red;
        }
    }

}

.form-group-mob {
    @include media-breakpoint-down(sm) {
        label {
            color: $white;
        }

        .form-control {
            color: $white;
            border-right: none;
            border-left: none;
            border-top: none;
            border-bottom: 1px solid $white;
            background-color: transparent;
            padding-left: 0;
        }
    }
}

.toggle-password-warpper {
    position: relative;

    .toggle-password {
        background-color: inherit;
        position: absolute;
        color: $gray-dark;
        cursor: pointer;
        padding: 15px;
        border: none;
        bottom: 0;
        right: 0;
        top: 0;
    }

    @include media-breakpoint-down(sm) {
        .toggle-password {
            padding: 0 15px;
        }
    }

    .form-control {
        padding-right: 45px;
    }
}

.custom-radio {
    cursor: pointer;
    .custom-control-input {
        ~ label.custom-control-label {
            cursor: pointer;
            &:before {
                background-color: $white-ice;
                border: 1px solid #E1E0E0;
            }
            span {
                font-size: 14px;
            }  
        }

        &:checked {
            ~ label.custom-control-label {
                &:before {
                    background-color: $white-ice;
                    border-color: $orange-light;
                    box-shadow: none;
                }

                &:after {
                    background-color: $orange-light;
                    background-image: none;
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    left: -20px; //before was 4px
                    top: 8px;
                }
            }
        }
    }
}

.custom-checkbox  {
    cursor: pointer;
    .custom-control-checkbox {
        display: none;
        ~ label.custom-control-label {
            cursor: pointer;
            &:before {
                background-color: $white-ice;
                border: 1px solid #E1E0E0;
                border-radius: 0;
            }
            span {
                font-size: 14px;
            }  
        }

        &:checked {
            ~ label.custom-control-label {
                &:before {
                    background-color: $white-ice;
                    border-color: $orange-light;
                    box-shadow: none;
                }

                &:after {
                    background-color: $orange-light;
                    background-image: none;
                    height: 8px;
                    width: 8px;
                    left: -20px; //before was 4px
                    top: 8px;
                }
            }
        }
    }
}

.select2-container--default
.select2-selection--single {
    border: 1px solid #e1e0e0;
    background: #F8F8F8;
    border-radius: 3px;
    padding: 0.72rem;
    height: auto;
    width: 100% !important;
}

.select2-container--default
.select2-selection--single
.select2-selection__arrow {
    height: 100%;
}

// Adicionar Arquivo
div.upload-file,
label.upload-file,
button.upload-file {
    background-color: #f8f8f8;
    border: 1px solid #e1e0e0;
    border-radius: 5px;
    text-align: center;
    color: #f78e00;
    line-height: 1em;
    display: block;
    padding: 20px;
    width: 100%;
    
    i {
        transition: transform 150ms ease;
        margin-bottom: 3px;
        font-size: 40px;
        display: block;
    }
    
    span {
        font-size: 14px;
    }
    
    &:hover {
        border: 1px solid #bfbfbf;
        text-decoration: none;
        
        i {
            transform: scale(0.9);
        }
    }
}

.amount {
    display: inline-block;
    color: $gray-dark;

    @include media-breakpoint-down(sm) {
        float: right;
    }

    .select2-container--default
    .select2-selection--single {
        padding: 0.02rem;
        height: 30px;
        width: 70px;
    }

    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
        right: -25px;
    }
}

.selectConfigAssessment {
    width:100%;
}
