.default-table{
    thead{
        tr{
            th{
                font-weight: bold;
                text-align: center;
                padding: 10px;
                border: 1px solid gainsboro;
            }
        }
    }
    tbody{
        tr{
            td{
                text-align: center;
                padding: 10px;
                border: 1px solid gainsboro;
            }
        }
    }
}