.ea-save {
    display: inline-block;
    text-transform: none;
    font: $font-primary;
    font-weight: normal;
    color: #9A9A9A;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 6px;
    opacity: 0;


    &.animation{
        animation: FadeAnimation 3s;
    }

    @include media-breakpoint-down(sm) {
        float: right;
        margin-top: 6px;
        font-size: 12px;
    }

    i {
        margin-right: 5px;
    }

    &.display-none{
        display: none;
    }
}

@keyframes FadeAnimation {
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }



.ea-materia {
    font-weight: bold;
    margin-top: 5px;
    color: #867A65;
    font-size: 14px;
    
    i {
        margin-right: 10px;
    }
}

.padding-zero {
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

.action-bar {
    align-items: flex-end;
    flex-direction: row;
    text-align: right;
    margin-bottom: 10px;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
        background-color: $white-ice;
        margin-top: 20px;
    }

    .action-bar-item {
        display: inline-block;
        text-decoration: none;
        vertical-align: top;
        text-align: center;
        margin-left: 30px;
        color: $gray-dark;
        font-size: 13px;
        width: 70px;
        cursor: pointer;

        svg{
            font-size: 30px;
            margin-bottom: 5px;
        }

        @include media-breakpoint-down(lg) {
            // margin: auto;
            padding: 10px;
            font-size: 11px;
        }

        span {
            display: block;
        }

        i {
            font-size: 35px;
            display: block;
        }

        &:hover {
            color: $orange;
        }
    }
}
