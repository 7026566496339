.ReactTable .rt-thead.-header {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.15);
  font-weight: bold;
}

.ReactTable .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer,
.ReactTable .rt-tbody .rt-td {
  padding: 12px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.circle {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 5px auto;

  &-true {
    background-color: green;
  }

  &-false {
    background-color: red;
  }
}
